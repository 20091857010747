<template>
  <Component ref="elTransition" :is="__transitionMap[props.name]" />
</template>

<script lang="ts" setup>
import type {
  TPageTransitionExpose,
  TPageTransitionProps,
} from '~/components/pageTransition/pageTransition.type.js'
import __transitionMap from './pageTransition.map.js'

const props = withDefaults(defineProps<TPageTransitionProps>(), {
  name: 'bubble',
})

defineExpose<TPageTransitionExpose>({
  leave,
  afterLeave,
  beforeEnter,
  enter,
})

const elTransition = ref()

function leave(callback: () => void) {
  elTransition?.value.leave(callback)
}

function afterLeave() {
  elTransition?.value.afterLeave()
}

function beforeEnter() {
  elTransition?.value.beforeEnter()
}

function enter(callback: () => void) {
  elTransition?.value.enter(callback)
}
</script>
