import type { CookieRef } from '#app'
import { defineStore } from 'pinia'
import { __hotkey } from '@lotsof/sugar/keyboard'

import { __isInIframe } from '@lotsof/sugar/is'

import __wrapIntoIframe from './utils/wrapIntoIframe.js'

export type TAdminToolbarStore = {
  preferences: ComputedRef<TAdminToolbarStorePreferences>
  setPreferences: (
    newPreferences: Partial<TAdminToolbarStorePreferences>,
  ) => void
}

export type TAdminToolbarStorePreferences = {
  draftMode: boolean
  displaySpacers: boolean
  highlightComponents: boolean
  displayToolbar: boolean
  device:
    | 'mobile'
    // | 'mobile-horizontal'
    | 'tablet'
    | 'tablet-horizontal'
    | 'desktop'
}

export const useAdminToolbarStore = defineStore('adminToolbar', () => {
  const appStore = useAppStore()

  let defaultPreferences: TAdminToolbarStorePreferences = {
    draftMode: appStore.stage === 'preview',
    displaySpacers: false,
    displayToolbar: true,
    highlightComponents: false,
    device: 'desktop',
  }

  const devices = [
    'mobile',
    // 'mobile-horizontal',
    'tablet',
    'tablet-horizontal',
    'desktop',
  ]

  let $contextDocument: Document = document

  let adminToolbarPreferencesCookie: CookieRef<TAdminToolbarStorePreferences>

  function _refreshAdminToolbarData(): void {
    adminToolbarPreferencesCookie = useCookie<TAdminToolbarStorePreferences>(
      '__adminToolbarPreferences',
      {
        default: () => defaultPreferences,
        watch: true,
      },
    )
  }

  /**
   * Restore the user state from the admin cookie.
   */
  function restore() {
    _refreshAdminToolbarData()
    if (import.meta.browser && adminToolbarPreferencesCookie?.value) {
      setPreferences(adminToolbarPreferencesCookie.value)
    }
  }

  /**
   * Init hotkeys
   */
  function initHotkeys($context: Document): void {
    __hotkey(
      'cmd+m',
      () => {
        setPreferences({
          displayToolbar: !adminToolbarPreferencesCookie.value.displayToolbar,
        })
      },
      {
        ctx: $context,
      },
    )
    __hotkey(
      'cmd+d',
      () => {
        setPreferences({
          draftMode: !adminToolbarPreferencesCookie.value.draftMode,
        })
      },
      {
        ctx: $context,
      },
    )
    __hotkey(
      'cmd+s',
      () => {
        setPreferences({
          displaySpacers: !adminToolbarPreferencesCookie.value.displaySpacers,
        })
      },
      {
        ctx: $context,
      },
    )

    __hotkey(
      'cmd+ArrowUp',
      () => {
        const currentIndex = devices.indexOf(
          adminToolbarPreferencesCookie.value.device,
        )
        const nextIndex =
          currentIndex === devices.length - 1 ? 0 : currentIndex + 1

        setPreferences({
          // @ts-ignore
          device: devices[nextIndex],
        })
      },
      {
        ctx: $context,
      },
    )
    __hotkey(
      'cmd+ArrowDown',
      () => {
        const currentIndex = devices.indexOf(
          adminToolbarPreferencesCookie.value.device,
        )
        const nextIndex =
          currentIndex === 0 ? devices.length - 1 : currentIndex - 1
        setPreferences({
          // @ts-ignore
          device: devices[nextIndex],
        })
      },
      {
        ctx: $context,
      },
    )
  }

  /**
   * Set preferences
   */
  function setPreferences(
    newPreferences: Partial<TAdminToolbarStorePreferences>,
  ): void {
    const currentPreferences = Object.assign(
      {},
      adminToolbarPreferencesCookie.value,
    )

    adminToolbarPreferencesCookie.value = {
      ...adminToolbarPreferencesCookie.value,
      ...newPreferences,
    }

    // special behavior for draft mode
    if (
      newPreferences.draftMode !== undefined &&
      newPreferences.draftMode !== currentPreferences.draftMode
    ) {
      document.location.reload()
    }

    if (adminToolbarPreferencesCookie.value.displaySpacers) {
      $contextDocument.body.classList.add('-display-spacers')
    } else {
      $contextDocument.body.classList.remove('-display-spacers')
    }

    if (!__isInIframe()) {
      if (adminToolbarPreferencesCookie.value.device) {
        let changeDeviceTimeout = 0
        const changeDevice = () => {
          setTimeout(() => {
            document.body.classList.remove(...devices.map((d) => `-${d}`))
            document.body.classList.add(
              `-${adminToolbarPreferencesCookie.value.device}`,
            )
          }, changeDeviceTimeout)
        }

        if (adminToolbarPreferencesCookie.value.device !== 'desktop') {
          setTimeout(() => {
            switch (adminToolbarPreferencesCookie.value.device) {
              case 'mobile':
              case 'tablet-horizontal':
              case 'tablet':
                if (!document.querySelector('.admin-toolbar-iframe')) {
                  changeDeviceTimeout = 500
                  const $adminToolbar = document.querySelector('.admin-toolbar')
                  if ($adminToolbar) {
                    // remove the toolbar to avoid including it into the iframe
                    $adminToolbar?.parentElement?.removeChild($adminToolbar)
                    // wrap the content into an iframe
                    const $iframe = __wrapIntoIframe()
                    // add the toolbar back
                    document.body.appendChild($adminToolbar)
                    // update the context
                    $contextDocument = $iframe.contentWindow
                      ?.document as Document
                    // hide the scrollbar
                    const $style = $contextDocument.createElement('style')
                    $style.setAttribute('type', 'text/css')
                    $style.innerHTML = `
                      body::-webkit-scrollbar {
                        width: 0;
                        height: 0;
                      }
                      body::-webkit-scrollbar-track {
                        background: transparent;               
                      }
                      body::-webkit-scrollbar-thumb {
                        background: transparent;
                      }
                    `
                    setTimeout(() => {
                      // append the style to the iframe
                      $contextDocument.head.appendChild($style)
                      // remove the 'admin-toolbar' class from the body
                      $contextDocument.body.classList.remove('-admin-toolbar')
                      // init hotkeys inside the iframe
                      initHotkeys($contextDocument)
                    }, 1000)
                  }
                }
                break
            }
            // process to change
            changeDevice()
          }, 100)
        } else {
          // process to change directly
          changeDevice()
        }
      }
    }
  }

  // restore the admin toolbar state
  restore()

  // init hotkeys
  initHotkeys($contextDocument)

  return {
    setPreferences,
    get preferences(): ComputedRef<TAdminToolbarStorePreferences> {
      return computed(() => {
        return adminToolbarPreferencesCookie?.value ?? defaultPreferences
      })
    },
  } as TAdminToolbarStore
})
