import './lenis.css'
import __lenis from 'lenis'
import { gsap as __gsap } from 'gsap'
import { ScrollTrigger as __ScrollTrigger } from 'gsap/ScrollTrigger'
import { __isInIframe } from '@lotsof/sugar/is'
import { ELenisEvents } from '~/lib/lenis/lenis.type'

export default function init(): __lenis | undefined {
  if (__isInIframe()) {
    return
  }

  // create a lenis instance
  const lenis = new __lenis()

  // Update the lenis RAF with the GSAP ticker
  __gsap.ticker.add((time) => {
    lenis.raf(time * 1000)
  })

  // Synchro with ScrollTrigger
  lenis.on('scroll', () => {
    __ScrollTrigger.update()
  })

  // Force the lenis to scroll to the top
  lenis.scrollTo(0, { immediate: true })

  // Events
  document.addEventListener(ELenisEvents.SCROLL_LOCK, () => {
    lenis.stop()
  })

  document.addEventListener(ELenisEvents.SCROLL_UNLOCK, () => {
    lenis.start()
  })

  return lenis
}
