<template>
  <nav
    :class="`admin-toolbar ${adminToolbarStore.preferences.displayToolbar ? '-active' : ''}`"
  >
    <div
      class="_brand"
      v-on:pointerup="
        () => {
          adminToolbarStore.setPreferences({
            displayToolbar: !adminToolbarStore.preferences.displayToolbar,
          })
        }
      "
    >
      <svg viewBox="0 0 139 139" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_5092_4228)">
          <path
            d="M124.492 69.4858L129.177 64.2543C134.14 58.7078 137.392 51.8427 138.539 44.4883C139.687 37.1338 138.68 29.6045 135.641 22.8097C132.602 16.0149 127.661 10.2453 121.414 6.19761C115.168 2.14996 107.883 -0.00256148 100.439 2.28752e-06H0V15.2693H97.3529C100.446 15.2237 103.518 15.7937 106.389 16.946C109.261 18.0983 111.874 19.81 114.078 21.9815C116.281 24.153 118.031 26.741 119.226 29.595C120.42 32.4489 121.036 35.5119 121.036 38.6057C121.036 41.6996 120.42 44.7625 119.226 47.6165C118.031 50.4704 116.281 53.0584 114.078 55.2299C111.874 57.4014 109.261 59.1131 106.389 60.2655C103.518 61.4178 100.446 61.9877 97.3529 61.9422H0V77.2216H97.3529C100.446 77.176 103.518 77.746 106.389 78.8983C109.261 80.0506 111.874 81.7623 114.078 83.9338C116.281 86.1053 118.031 88.6933 119.226 91.5473C120.42 94.4012 121.036 97.4642 121.036 100.558C121.036 103.652 120.42 106.715 119.226 109.569C118.031 112.423 116.281 115.011 114.078 117.182C111.874 119.354 109.261 121.065 106.389 122.218C103.518 123.37 100.446 123.94 97.3529 123.895H0V139.002H100.439C107.883 139.004 115.168 136.852 121.414 132.804C127.661 128.757 132.602 122.987 135.641 116.192C138.68 109.397 139.687 101.868 138.539 94.5136C137.392 87.1591 134.14 80.2941 129.177 74.7475L124.492 69.4858Z"
            fill="black"
          />
          <path
            d="M104.952 38.6032C104.949 36.5805 104.145 34.6415 102.715 33.2112C101.284 31.781 99.3452 30.9763 97.3226 30.9736H0V46.2378H97.3226C99.3457 46.2338 101.285 45.428 102.715 43.997C104.145 42.5659 104.949 40.6263 104.952 38.6032Z"
            fill="black"
          />
          <path
            d="M104.952 100.541C104.949 98.5177 104.145 96.5782 102.715 95.1471C101.285 93.7161 99.3457 92.9103 97.3226 92.9062H0V108.17H97.3226C99.3452 108.168 101.284 107.363 102.715 105.933C104.145 104.503 104.949 102.564 104.952 100.541Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_5092_4228">
            <rect
              width="139"
              height="139"
              fill="white"
              transform="matrix(-1 0 0 1 139 0)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
    <div class="_body">
      <div class="_tools">
        <label class="_tool">
          <div class="_icon" v-html="__icons.draftMode"></div>
          <p class="_label">Draft mode</p>
          <!-- <Hotkey keys="cmd+d" popover /> -->
          <input
            type="checkbox"
            disabled
            :checked="adminToolbarStore.preferences.draftMode"
          />
        </label>
        <div class="_tool -dropdown">
          <div class="_icon" v-html="__icons.layout"></div>
          <p class="_label">Layout</p>
          <div class="_dropdown">
            <label class="_tool">
              <div class="_icon" v-html="__icons.spacers"></div>
              <p class="_label">Spacers visibility</p>
              <Hotkey keys="cmd+s" popover />
              <input
                type="checkbox"
                :checked="adminToolbarStore.preferences.displaySpacers"
                @change="
                  (e) => {
                    adminToolbarStore.setPreferences({
                      displaySpacers:
                        !adminToolbarStore.preferences.displaySpacers,
                    })
                  }
                "
              />
            </label>
            <label class="_tool">
              <div class="_icon" v-html="__icons.components"></div>
              <p class="_label">Highlight components</p>
              <Hotkey keys="cmd+c" popover />
              <input
                type="checkbox"
                :checked="adminToolbarStore.preferences.highlightComponents"
                @change="
                  (e) => {
                    adminToolbarStore.setPreferences({
                      highlightComponents:
                        !adminToolbarStore.preferences.highlightComponents,
                    })
                  }
                "
              />
            </label>
          </div>
        </div>
        <div class="_tool -dropdown">
          <div class="_icon" v-html="__icons.devices"></div>
          <p class="_label">Devices</p>
          <div class="_dropdown">
            <label class="_tool">
              <div class="_icon" v-html="__icons.desktop"></div>
              <p class="_label">Desktop</p>
              <input
                type="checkbox"
                :checked="adminToolbarStore.preferences.device === 'desktop'"
                @change="
                  (e) => {
                    adminToolbarStore.setPreferences({
                      device: 'desktop',
                    })
                  }
                "
              />
            </label>
            <label class="_tool">
              <div
                class="_icon"
                style="rotate: -90deg"
                v-html="__icons.tablet"
              ></div>
              <p class="_label">Tablet horizontal</p>
              <input
                type="checkbox"
                :checked="
                  adminToolbarStore.preferences.device === 'tablet-horizontal'
                "
                @change="
                  (e) => {
                    adminToolbarStore.setPreferences({
                      device: 'tablet-horizontal',
                    })
                  }
                "
              />
            </label>
            <label class="_tool">
              <div class="_icon" v-html="__icons.tablet"></div>
              <p class="_label">Tablet</p>
              <input
                type="checkbox"
                :checked="adminToolbarStore.preferences.device === 'tablet'"
                @change="
                  (e) => {
                    adminToolbarStore.setPreferences({
                      device: 'tablet',
                    })
                  }
                "
              />
            </label>
            <label class="_tool">
              <div class="_icon" v-html="__icons.mobile"></div>
              <p class="_label">Mobile</p>
              <input
                type="checkbox"
                :checked="adminToolbarStore.preferences.device === 'mobile'"
                @change="
                  (e) => {
                    adminToolbarStore.setPreferences({
                      device: 'mobile',
                    })
                  }
                "
              />
            </label>
          </div>
        </div>
      </div>
      <div class="_user">
        <button class="_button" onclick="logout">
          <p class="_icon" v-html="__icons.logout"></p>
          <Hotkey keys="logout" popover />
        </button>
      </div>
    </div>
  </nav>
</template>
<script setup lang="ts">
import './adminToolbar.css'
import './adminToolbarIframe.css'
import Hotkey from '~/components/ui/hotkey/hotkey.vue'
import * as __icons from './icons/_exports.js'
import { useAdminToolbarStore } from './adminToolbar.store.js'
import { type TAdminToolbar } from './adminToolbar.type'

const props = withDefaults(defineProps<TAdminToolbar>(), {
  logout: () => {},
})
const adminToolbarStore = useAdminToolbarStore()

useHead({
  bodyAttrs: {
    class: computed(() => {
      return '-admin-toolbar'
    }),
  },
})
</script>
