import { gsap as __gsap } from 'gsap'
import { ScrollTrigger as __ScrollTrigger } from 'gsap/ScrollTrigger'
import { MotionPathPlugin as __MotionPathPlugin } from 'gsap/MotionPathPlugin'
import { SplitText as __SplitText } from './plugins/SplitText'

export default function __initGsap(): typeof __gsap {
  // register plugins
  __gsap.registerPlugin(__ScrollTrigger, __MotionPathPlugin, __SplitText)

  // Fix the ticker to 60fps
  __gsap.ticker.fps(60)

  // set lag smoothing
  __gsap.ticker.lagSmoothing(0)

  // return the instance
  return __gsap
}
